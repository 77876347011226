
$(document).ready(function() {
    let height = document.getElementById("content").offsetHeight;

    let scene, camera, renderer, cloudParticles = [];

    function init() {
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(60, window.innerWidth / height, 1, 1000);
        camera.position.z = 1;
        camera.rotation.x = 1.16;
        camera.rotation.y = -0.12;
        camera.rotation.z = 0.27;
        
        let ambient = new THREE.AmbientLight(0x404040);
        scene.add(ambient);

        let directionalLight = new THREE.DirectionalLight(0xffffff);
        directionalLight.position.set(0,0,1);
        scene.add(directionalLight);

        renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setClearColor( 0x000000, 0 ); // the default
        renderer.setSize(window.innerWidth, height);
        document.getElementById("fog").appendChild(renderer.domElement);

        let loader = new THREE.TextureLoader();
        loader.load("./assets/images/assets/white-smoke.png", function(texture) {
            let cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
            let cloudMaterial = new THREE.MeshLambertMaterial({
                map: texture,
                transparent: true
            });
            for(let i=0; i<15; i++) {
                let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
                cloud.position.set(
                    Math.random()*1200-400,
                    300,
                    Math.random()*300-500
                );
                cloud.rotation.x = 1.16;
                cloud.rotation.y = -0.12;
                cloud.rotation.z = Math.random()*2*Math.PI;
                cloud.material.opacity = 0.35;
                cloudParticles.push(cloud);
                scene.add(cloud);
            }
        });
        window.addEventListener("resize", onWindowResize, false);
        render();
    }
    function onWindowResize() {
        let newHeight = document.getElementById("content").offsetHeight;
        camera.aspect = window.innerWidth / newHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, newHeight);
    }
    function render() {
        cloudParticles.forEach(p => {
            p.rotation.z -= 0.0015;
        })
        renderer.render(scene, camera);
        requestAnimationFrame(render);
    }
    init();
});